import React from 'react';
import './ConfirmPopup.css';

function ConfirmPopup({ message, onConfirm, onCancel }) {
    return (
        <div className="confirm-popup">
            <div className="confirm-popup-content">
                <p>{message}</p>
                <button onClick={onConfirm}>Yes</button>
                <button onClick={onCancel}>No</button>
            </div>
        </div>
    );
}

export default ConfirmPopup;
